export const convertDateFormat = (givenDate:any): string | void  => {
  // to convert the format yyyy-mm-dd used localestring
  try {
    if (new Date(givenDate) instanceof Date) {
      return new Date(givenDate)?.toLocaleDateString('sv-SE');
    }
  } catch (e) {
    console.log(e);
  }
};
export const setLastYearFirstMonthAsMinDateToCalendar = () => {
  return new Date(
    new Date(
      new Date().setMonth(new Date().getMonth() - (12 + new Date().getMonth()))
    ).setDate(1)
  );
};
