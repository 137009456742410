<div class="grid-outer">
  <div class="breadcrumb">
    <div class="breadcrumb-inner">
      <div class="icon-sm home-svg"></div>
      <div class="welcome-outer">
        <span class="welcome-text">{{ welcomeString }}</span>
        <span class="icon-md arrow-svg"></span>
      </div>
      <div>
        <ul class="list">
          <li>
            <a href="#">{{ slaSummaryViewString }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="page-title">
    <div class="text-left title-text">{{ slaSummaryViewString }}</div>
  </div>
  <div class="paginator">
    <div class="text-right">
      <rcp-paginator
        [first]="first"
        [rows]="rows"
        [totalRecords]="totalRecords"
        (onPageChangeEvent)="onPageChange($event)"
        #paginator
      ></rcp-paginator>
    </div>
  </div>
  <div class="tab-filter">
    <div class="text-left">
      <rcp-tab-filter
        (selectButtonValueEvent)="onExpandOneLevel($event)"
        [selectedOption]="tabName"
      ></rcp-tab-filter>
    </div>
  </div>
  <div class="apply-reset-button">
    <div class="button-inner">
      <button
        type="button"
        class="btn-secondary-lg"
        (click)="downloadGridRecords()"
      >
        {{ downloadRecords }}
      </button>
      <button
        type="button"
        class="btn-secondary-lg"
        (click)="resetAllFilterValues($event)"
      >
        {{ resetfiltersString }}
      </button>
      <button type="button" class="btn-primary-lg" (click)="appyFilter($event)">
        {{ applyfiltersString }}
      </button>
    </div>
  </div>
  <div class="dropdowns">
    <div class="dropdowns-inner">
      <rcp-date-selector
        [calenderDateInputPlaceholder]="placeholderValue"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [defaultStartDate]="datesFromSummary.startDate"
        [defaultEndDate]="datesFromSummary.endDate"
        [isClearSearchData]="isClearSearchData"
        [defaultDropdownOption]="defaultDropdownOption"
        [isAddLast24HoursOption]="true"
        [isAddLast7DaysOption]="true"
        [isAddLast2WeeksOption]="true"
        [isAddLast30DaysOption]="true"
        [isAddLast90DaysOption]="true"
        (clearSearchDataEvent)="clearSelectedDate($event)"
        (dateSearchEvent)="dateSearchEvent($event)"
        (dropdownOptionSelectedEvent)="dropdownOptionSelected($event)"
      ></rcp-date-selector>
      <p-dropdown
        [options]="dateTypeOptions"
        [(ngModel)]="onTimeShipFilters.dateType"
        placeholder="{{ dateTypeString }}"
      />
      <p-dropdown
        [options]="slaTypeOptions"
        [(ngModel)]="onTimeShipFilters.filters.sla"
        placeholder="{{ slaString }}"
      />
      <p-multiSelect
        [options]="orderTypes"
        [(ngModel)]="onTimeShipFilters.filters.order_type"
        placeholder="{{ orderTypeString }}"
        display="chip"
        [showHeader]="false"
      />
      <p-multiSelect
        [options]="shippingMethod"
        [(ngModel)]="onTimeShipFilters.filters.shipping_method"
        placeholder="{{ shipMethodString }}"
        display="chip"
        [showHeader]="false"
      />
      <p-multiSelect
        [options]="carrier"
        [(ngModel)]="onTimeShipFilters.filters.carrier"
        placeholder="{{ carrierString }}"
        display="chip"
        [showHeader]="false"
      />
      <p-multiSelect
        [options]="destination"
        [(ngModel)]="onTimeShipFilters.filters.destination"
        placeholder="{{ destinationCountryString }}"
        display="chip"
        [showHeader]="false"
      />
      <p-multiSelect
        [options]="orderStatus"
        [(ngModel)]="onTimeShipFilters.filters.order_status"
        placeholder="{{ statusString }}"
        display="chip"
        [showHeader]="false"
      />
    </div>
  </div>
  <div class="main-grid">
    <rcp-grid
      [gridData]="gridData"
      [headerColumns]="headerColumns"
      [first]="first"
      [rows]="rows"
      [passGridName]="passGridName"
      [hideFilterAndSorting]="hideFilterAndSorting"
      [hideSorting]="hideSorting"
      [hideFilter]="hideFilter"
      (onGridDateClickEvent)="onGridDateClickEvent($event)"
    ></rcp-grid>
  </div>
</div>
