import { DatePipe } from '@angular/common';
import {
  Component,
  DestroyRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { SlaOnTimeShipService } from '../../../services/sla-ontimeship-service/sla-on-time-ship.service';
import { DateSelectorComponent } from '../../../shared/components/date-selector/date-selector.component';
import { GridComponent } from '../../../shared/components/grid/grid.component';
import { PaginatorComponent } from '../../../shared/components/paginator/paginator.component';
import { TabFilterComponent } from '../../../shared/components/tab-filter/tab-filter.component';
import { setLastYearFirstMonthAsMinDateToCalendar } from '../../../shared/reuse-methods/reuse-methods';
import { slaSummeryViewHeader } from '../header-columns';
import { SLAFilters } from '../model/interface';
@Component({
  selector: 'rcp-sla',
  standalone: true,
  imports: [
    GridComponent,
    PaginatorComponent,
    TabFilterComponent,
    DateSelectorComponent,
    DropdownModule,
    FormsModule,
    MultiSelectModule,
  ],
  providers: [DatePipe],
  templateUrl: './sla.component.html',
  styleUrl: './sla.component.scss',
})
export class SlaComponent implements OnInit {
  @ViewChild(GridComponent) childComponent!: GridComponent;
  @ViewChild('paginator', { static: false }) paginator: PaginatorComponent;
  gridData: any[] = [];
  headerColumns: any;
  totalRecords: number = 0;
  first: number = 0;
  rows: number = 5;
  passGridName: string = 'sla-main-page';
  filterValues: any;
  dateTypeOptions = ['Interfaced Date', 'Due Date', 'Shipped Date'];
  slaTypeOptions: any[] | undefined;
  orderTypes: any[] | undefined;
  shippingMethod: any[] | undefined;
  carrier: any[] | undefined;
  destination: any[] | undefined;
  orderStatus: any[] | undefined;
  componentIsDestroyed = inject(DestroyRef);
  router = inject(Router);
  isClearSearchData: boolean = false;
  onTimeShipFilters: SLAFilters = {
    filters: {
      order_status: null,
      order_type: null,
      shipping_method: null,
      carrier: null,
      sla: 'SLA1',
      sla_status: null,
      destination: null,
    },
    dates: {
      startDate: null,
      endDate: null,
    },
    dateType: null,
  };
  currentDate: any = new Date();
  nextMonthLastDate: any = new Date(
    this.currentDate.getFullYear(),
    this.currentDate.getMonth() + 2,
    0
  );
  minDate: any = setLastYearFirstMonthAsMinDateToCalendar();
  maxDate: any = this.nextMonthLastDate;
  datesFromSummary: { startDate: Date; endDate: Date };
  defaultDropdownOption: any;
  parentRowField: {} = {};
  childRowField: {} = {};
  innerChildRowField: {} = {};
  childRowFieldArray = [];
  innerChildRowFieldArray = [];
  summaryViewData = [];
  tabName: string = 'monthly';
  hideFilterAndSorting = false;
  hideSorting = false;
  hideFilter = false;
  pageLimit: number = 500;
  //binding text into html
  welcomeString: string = 'Welcome';
  slaSummaryViewString: string = 'On Time Ship SLA Summary View';
  resetfiltersString: string = 'Reset filters';
  applyfiltersString: string = 'Apply filters';
  downloadRecords: string = 'Download Records';
  placeholderValue: string = 'Date';
  dateTypeString: string = 'Date type';
  slaString: string = 'SLA';
  orderTypeString: string = 'Order type';
  shipMethodString: string = 'Ship method';
  carrierString: string = 'Carrier';
  destinationCountryString: string = 'Destination country';
  statusString: string = 'Status';
  constructor(
    private slaOnTimeShipService: SlaOnTimeShipService,
    private datePipe: DatePipe
  ) {}
  ngOnInit() {
    this.gridHeaderData();
    this.getFilterValues();
    this.setFilterDate();
    this.createSummeryObject();
  }
  // Get the grid header data
  gridHeaderData() {
    this.headerColumns = slaSummeryViewHeader;
  }
  // This function used for getting the dropdown values
  getFilterValues() {
    const client = sessionStorage.getItem('companyname');
    this.slaOnTimeShipService
      .getSlaFilterValues(client)
      .pipe(takeUntilDestroyed(this.componentIsDestroyed))
      .subscribe((data: any) => {
        if (data.response && data.response.length !== 0) {
          this.filterValues = data.response[0];
          this.slaTypeOptions = this.filterValues?.slaType;
          this.orderTypes = this.filterValues?.orderType;
          this.shippingMethod = this.filterValues?.shippingMethod;
          this.carrier = this.filterValues?.carrier;
          this.destination = this.filterValues?.destination;
          this.orderStatus = this.filterValues?.orderStatus;
        }
      });
  }
  //Set initial date of filter
  setFilterDate() {
    this.datesFromSummary = {
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0),
    };
  }
  // Create summery object for api call
  createSummeryObject() {
    const fromDate = this.datePipe.transform(
      this.datesFromSummary.startDate,
      'yyyy-MM-dd'
    );
    const toDate = this.datePipe.transform(
      this.datesFromSummary.endDate,
      'yyyy-MM-dd'
    );
    const summaryType = {
      summaryType: 'm' as 'M',
      slaType: 'SLA1',
      filters: null,
      dateType: null,
      offset: 0,
      fromDate: fromDate,
      toDate: toDate,
      pageLimit: this.pageLimit,
    };
    this.getDataFromSummaryAPI(summaryType);
  }
  // In this function we get the grid data from api.
  getDataFromSummaryAPI(summaryType: any) {
    this.gridData = [];
    this.slaOnTimeShipService
      .getSlaSummaryView(summaryType)
      .pipe(takeUntilDestroyed(this.componentIsDestroyed))
      .subscribe((data: any) => {
        this.summaryViewData = data.response?.rows;
        this.totalRecords = data.response.totalCount;
        let dataTypeChange;
        let responseDataType = data.response.dateType;
        if (responseDataType === 'interfacedDate') {
          dataTypeChange = 'Interfaced Date';
        } else if (responseDataType === 'dueDate') {
          dataTypeChange = 'Due Date';
        } else {
          dataTypeChange = 'Shipped Date';
        }
        this.onTimeShipFilters.dateType = dataTypeChange;
        this.nestingSlaTable();
        this.onPageChange();
      });
  }
  // This function is used for returning the quarters
  allMonths(value: string) {
    if (value == 'Jan' || value == 'Feb' || value == 'Mar') {
      return 'Q1';
    } else if (value == 'Apr' || value == 'May' || value == 'Jun') {
      return 'Q2';
    } else if (value == 'Jul' || value == 'Aug' || value == 'Sep') {
      return 'Q3';
    } else {
      return 'Q4';
    }
  }
  // This function is for grid nesting table. We have created nesting data table using below code.
  nestingSlaTable() {
    this.summaryViewData.forEach((item: any) => {
      const quarter = this.allMonths(item.monthname);
      this.childRowFieldArray = [];
      this.innerChildRowFieldArray = [];
      this.parentRowField = {
        date:
          quarter +
          ' ' +
          '-' +
          ' ' +
          item.monthname.toUpperCase() +
          ' ' +
          item.year,
        shippingDate: '-',
        orderShipped: item.orders_due,
        ordersPassed: item.orders_passed,
        ordersFailed: item.orders_failed,
        unshippedOrders: item.unshipped_orders,
        exceptionOrders: item.exception_orders,
        SLA1: item.sla,
      };
      if (item.weeklySummary) {
        item.weeklySummary.forEach((childItem: any) => {
          this.innerChildRowFieldArray = [];
          this.childRowField = {
            date: 'Week' + ' ' + childItem.week,
            shippingDate: '-',
            orderShipped: childItem.orders_due,
            ordersPassed: childItem.orders_passed,
            ordersFailed: childItem.orders_failed,
            unshippedOrders: childItem.unshipped_orders,
            exceptionOrders: childItem.exception_orders,
            SLA1: childItem.sla,
          };
          childItem?.dailySummary.forEach((innerChildItem: any) => {
            this.innerChildRowField = {
              date: '-',
              shippingDate: innerChildItem.date,
              orderShipped: innerChildItem.orders_due,
              ordersPassed: innerChildItem.orders_passed,
              ordersFailed: innerChildItem.orders_failed,
              unshippedOrders: innerChildItem.unshipped_orders,
              exceptionOrders: innerChildItem.exception_orders,
              SLA1: innerChildItem.sla,
            };
            this.innerChildRowFieldArray.push({
              data: this.innerChildRowField,
            });
          });
          this.childRowFieldArray.push({
            data: this.childRowField,
            children: this.innerChildRowFieldArray,
          });
        });
      }
      this.gridData.push({
        data: this.parentRowField,
        children: this.childRowFieldArray,
      });
    });
  }
  // This function is used for tabing of month,week and day.
  onExpandOneLevel(event: any) {
    this.tabName = event;
    this.expandNodes(this.gridData, this.tabName);
    this.gridData = [...this.gridData];
  }
  expandNodes(nodes: any, tabName: string) {
    const shouldExpande = tabName !== 'monthly';
    const childExpandValue =
      tabName === 'monthly' ? false : tabName !== 'weekly';
    nodes.forEach((node: any) => {
      node.expanded = shouldExpande;
      if (node.children) {
        node.children.forEach((child: any) => {
          child.expanded = childExpandValue;
        });
      }
    });
  }
  //Date event and conversion
  dateSearchEvent(event: any) {
    if (event) {
      this.datesFromSummary = {
        startDate: event[0],
        endDate: event[1],
      };
      const fromDate = this.datePipe.transform(
        this.datesFromSummary.startDate,
        'yyyy-MM-dd'
      );
      const toDate = this.datePipe.transform(
        this.datesFromSummary.endDate,
        'yyyy-MM-dd'
      );
      this.onTimeShipFilters.dates = {
        startDate: fromDate as null,
        endDate: toDate as null,
      };
    }
  }
  // Clear the date picker value and set the default value from date picker
  clearSelectedDate(event: any) {
    this.setFilterDate();
  }
  // when the user select the date picker dropdown value
  dropdownOptionSelected(event: any) {
    this.defaultDropdownOption = event;
  }
  // Data type conversion for api.
  dataTypeConversion(slaType: string) {
    if (slaType === 'Interfaced Date') {
      return 'interfacedDate';
    } else if (slaType === 'Due Date') {
      return 'dueDate';
    } else {
      return 'shippedDate';
    }
  }
  // This function is use for filter the data.
  appyFilter(event: any) {
    //below code is added to make empty arry as null other wise api will fail
    Object.keys(this.onTimeShipFilters.filters).forEach((key) => {
      this.onTimeShipFilters.filters[key] =
        this.onTimeShipFilters.filters[key]?.length > 0
          ? this.onTimeShipFilters.filters[key]
          : null;
    });
    const summaryType = 'm' as 'M';
    this.commonPayload(summaryType);
  }
  // Reset all filter value and call
  resetAllFilterValues(event: any) {
    this.isClearSearchData = true;
    this.defaultDropdownOption = null;
    this.setFilterDate();
    this.onTimeShipFilters = {
      filters: {
        order_status: null,
        order_type: null,
        shipping_method: null,
        carrier: null,
        destination: null,
        sla: 'SLA1',
      },
      dates: {
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: this.nextMonthLastDate,
      },
      dateType: undefined as any,
    };
    const summaryType = {
      summaryType: 'm' as 'M',
      slaType: 'SLA1',
      filters: null,
      dateType: null,
      offset: 0,
      fromDate: this.onTimeShipFilters.dates.startDate,
      toDate: this.onTimeShipFilters.dates.endDate,
      pageLimit: this.pageLimit,
    };
    this.childComponent.emptyGridOnReset();
    this.getDataFromSummaryAPI(summaryType);
    this.onExpandOneLevel('monthly');
  }
  // Handle paginator event
  onPageChange(event?: any) {
    if (event) {
      const start = event.first;
      this.paginator.changeValueForPaginator(start);
      this.childComponent.handleParentEvent(start);
    } else {
      this.paginator.changeValueForPaginator(this.first);
      this.childComponent.handleParentEvent(this.first, this.rows);
    }
  }
  // This function is used for directing the grid view list
  onGridDateClickEvent(event: any) {
    const eventData = {
      date: event,
      dateType: this.onTimeShipFilters.dateType,
      slaType: this.onTimeShipFilters.filters.sla,
      filterValues: this.filterValues,
    };
    this.router.navigate(['/outbound/on-time-ship-sla/list'], {
      state: { eventData },
    });
  }
  // Download the grid record and convert months,week and day
  downloadGridRecords() {
    let summaryType;
    const tabName = this.tabName;
    if (tabName === 'monthly') {
      summaryType = 'm' as 'M';
    } else if (tabName === 'weekly') {
      summaryType = 'w' as 'W';
    } else {
      summaryType = 'd' as 'D';
    }
    this.commonPayload(summaryType, 'downloadReport');
  }
  // Created common payload for apply filter and download resport
  commonPayload(summaryTypeDate: any, downloadReport?: any) {
    const summaryType = {
      summaryType: summaryTypeDate,
      slaType: this.onTimeShipFilters.filters.sla
        ? this.onTimeShipFilters.filters.sla
        : 'SLA1',
      filters: this.onTimeShipFilters.filters
        ? this.onTimeShipFilters.filters
        : null,
      dateType: this.onTimeShipFilters.dateType
        ? this.dataTypeConversion(this.onTimeShipFilters.dateType)
        : null,
      offset: 0,
      fromDate: this.onTimeShipFilters.dates.startDate
        ? this.onTimeShipFilters.dates.startDate
        : null,
      toDate: this.onTimeShipFilters.dates.startDate
        ? this.onTimeShipFilters.dates.endDate
        : null,
      pageLimit: this.pageLimit,
    };
    if (downloadReport === 'downloadReport') {
      const summeryPage = 'summery-view';
      let summaryTypeModify = JSON.parse(JSON.stringify(summaryType));
      delete summaryTypeModify.filters.sla;
      delete summaryTypeModify.offset;
      delete summaryTypeModify.pageLimit;
      this.slaOnTimeShipService.downloadSlaData(summaryTypeModify, summeryPage);
    } else {
      let summaryTypeModify = JSON.parse(JSON.stringify(summaryType));
      delete summaryTypeModify.filters.sla;
      this.getDataFromSummaryAPI(summaryTypeModify);
      this.onExpandOneLevel('monthly');
    }
  }
}
